import { useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useLocation, NavLink, Link } from 'react-router-dom';


export default function NavbarComponent() {

    useEffect(() => {
        updateActiveClassOnLoad();
    }, []);
    
    const updateActiveClassOnLoad = () => {         
        var navLinks = [];
        navLinks = document.querySelectorAll(".navbar-nav a.nav-link");
        if (navLinks !== null && navLinks !== undefined && navLinks.length > 0)
        {
            navLinks.forEach((navlink, ind) => {             
                document.getElementById(navlink.id).classList.remove("active");
            });
        }
      
        var split_path = window.location.pathname.split("/");
        var last_child = split_path[split_path.length - 1];
        if (last_child === "") { 
            document.getElementById("home").classList.add("active");
        }
        else if (last_child.length > 0)
        {
            document.getElementById(last_child).classList.add("active");
        }       
    }

    const updateActiveClass = (e) => {
        var navLinks = [];
        navLinks = document.querySelectorAll(".navbar-nav a.nav-link");
        if (navLinks !== null && navLinks !== undefined && navLinks.length > 0)
        {
            navLinks.forEach((navlink, ind) => {             
                document.getElementById(navlink.id).classList.remove("active");
            });
        }
        document.getElementById(e.target.id).classList.add("active");
    }

    return (
        <>
            <Navbar collapseOnSelect fixed="top" sticky="top" expand={'md'} bg="light" >
                <Container>
                    <Navbar.Brand className='navbar-brand'>
                        <i className="fas fa-globe my-logo" aria-hidden="true" style={{ color: '#314465!important', fontSize: '16px!important' }}>
                            <span className='brand-text'>
                                DEVDESKWEB
                            </span>
                        </i>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id="responsive-navbar-nav" style={{ float: 'right!important' }}>
                        <Nav className="ms-auto">
                            <Nav.Link as={Link} to="/" onClick={(e) => {updateActiveClass(e)}} id="home" >Home</Nav.Link>
                            <Nav.Link as={Link} to="/products" id="products" onClick={(e) => {updateActiveClass(e)}}>Products</Nav.Link>
                            <Nav.Link as={Link} to="/about" onClick={(e) => {updateActiveClass(e)}} id="about" >About</Nav.Link>                        
                            <Nav.Link as={Link} to="/services" onClick={(e) => {updateActiveClass(e)}} id="services" >Services</Nav.Link>
                            <Nav.Link as={Link} to="/contact" onClick={(e) => {updateActiveClass(e)}} id="contact" >Contact</Nav.Link>                                                                                                                                                       
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}