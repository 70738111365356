export default function About() { 
    return (
        <>
                      
            <div className='about'>  
                <br></br>
                <p className="welcome2">About Us</p>
                <div className='home-description about-description description'>
                    <div className='box-shadow-1'>
                        
                   {/* <p className="welcome2">About Us</p> */}
                    <img src='./assets/img/about-1.jpg' className="about-image"></img>
                    <div className="about-padding">                                            
                        <p>
                            We are indipendent software application developers. We are from Hyderabad, India.
                            We offer personalized and feature rich software solutions to boost up your organization growth.
                            We leverage the latest technologies and programming languages to implement your application development projects.
                            We utilize the best practice and development approaches for building software application solutions in industry standard manner.
                            We provide our agreed services by working remotely.
                        </p>
                        <p>
                            The general purpose of <b>devdeskweb.net</b> is to develop and promote advanced information technologies for multi-user operation.
                            <b>devdeskweb.net</b> is to develop and deliver the highest quality products, total client satisfaction, timely delivery of solutions and the best quality/price ratio found in the industry.
                        </p>

                        <p>
                            We are providing services for development, deployment, maintenance for web, desktop, android and ESRI Arc GIS platforms.
                            We are expereinced in developing front end, back end, server side applications, web API’s, desktop applications, android apps,
                            Esri Arc GIS Desktop Customization and ArcGIS Web Applications. We are expereinced in developing responsive, user friendly, interactive, data driven web applications using latest technologies & methodologies.
                            We are expereinced in developing custom tools and applications for Autodesk Auto CAD and Esri ArcGIS platforms.
                        </p>                                            
                        </div>
                         </div>
                </div>
            </div>
            <br></br>
        </>
    );
}

