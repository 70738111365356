import { Card } from "react-bootstrap";


export default function Services() { 
    return (
        <>
            <div className="services">
                <div className=''>
                    <p className="welcome2">Services</p>                    
                    <div className="services-container-grid">
                        <Card className="grid-item card servicesText description">
                            <Card.Header>Web Development</Card.Header>
                            <Card.Img src="./assets/img/web_640_427.jpg" />
                            <Card.Body>
                                <Card.Title>1. Web Application Developement</Card.Title>
                                <Card.Text>
                                    We develop responsive, user friendly, fully functional, interactive, data driven, secure, flexible and scalable
                                    Web applications using Server side technologies, frameworks like
                                    Microsoft ASP.NET, NodeJS etc.
                                </Card.Text>
                            </Card.Body>
                            <Card.Body>
                                <Card.Title>2. Responsive Website Development</Card.Title>
                                <Card.Text>
                                    We develop responsive, user friendly, interactive websites that can fits on any screen size using
                                    client side UI technologies & frameworks like HTML, CSS, Bootstrap, JavaScript, jQuery, ReactJS etc.
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className="grid-item card servicesText description">
                            <Card.Header>Arc GIS Development</Card.Header>
                            <Card.Img src="./assets/img/arcgis_640_427.jpg" />
                            <Card.Body>
                                <Card.Title>1. Arc GIS Web Development</Card.Title>
                                <Card.Text>
                                    We develop browser based Web applications for Esri ArcGIS using ArcGIS with JavaScript API, Dojo Toolkit,
                                    ArcGIS Server Services, using ASP.NET. We develop Esri ArcGIS Experience Builder applications also.
                                    
                                </Card.Text>
                            </Card.Body>
                            <Card.Body>
                                <Card.Title>2. Arc GIS Desktop Development</Card.Title>
                                <Card.Text>
                                    We develop custom automation tools, geoprocessing tools, addins, windows forms applications
                                    for esri ArcGIS Desktop to automate common, repetitive Core GIS tasks using Esri ArcGIS Arc Objects .NET SDK.
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className="grid-item card servicesText description">
                            <Card.Header>Open Source Development</Card.Header>
                            <Card.Img src="./assets/img/opensource_640_427.png" />
                            <Card.Body>
                                <Card.Title>1. OpenLayers Application Development</Card.Title>
                                <Card.Text>
                                    We develop Web browser based applications using Open Source GIS Technologies
                                    like OpenLayer, GeoServer, PostgreSql and required Web UI technologies, frameworks like HTML, CSS, Bootstrap, JavaScript, jQuery,
                                    and ReactJS.
                                </Card.Text>
                            </Card.Body>
                        </Card>


                        <Card className="grid-item card servicesText description">
                            <Card.Header>Auto CAD Development</Card.Header>
                            <Card.Img src="./assets/img/autocad_640_427.png" />
                            <Card.Body>
                                <Card.Title>1. Custom Tools Development</Card.Title>
                                <Card.Text>
                                    We develop custom automated tools, commands and desktop applications for Autodesk AutoCAD
                                    to automate common repetitive
                                    tasks like create, validate, manipulate, convert data using .NET Object ARX.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        
                    </div>
                 
                </div>
            </div>
            <br></br>
        </>
    );
}