import { wait } from "@testing-library/user-event/dist/utils";
import Footer from "./Footer";
import axios, { AxiosHeaders } from "axios";
import devdeskwebServices from "../Services/devdeskwebServices";
import ContactService from "../Services/ContactService";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faMailBulk, faMobile, faMobileAndroid, faMobilePhone, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faSkype } from "@fortawesome/free-brands-svg-icons";



export default function Contact() {    
    const sendMailHandler = async (evt) => {
        evt.preventDefault();
      
                  
        PostSendMail();
        //GetSendMail();
           
    }
    const PostSendMail = async () => {
        try {

            let MessageCustomer = document.getElementById("cMessage").value;
            let SubjectCustomer = document.getElementById("cSubject").value;
            let MailIDCustomer = document.getElementById("cMail").value;
            let MobileNumberCustomer = document.getElementById("cMobile").value;
            let NameCustomer = document.getElementById("cName").value;
                  
            let UrlFullPath = "";
            
            // if (window.location.protocol == "http:") {
            //     if (window.location.hostname == "localhost") {
            //         UrlFullPath = "http://localhost:54484/api/EMail/SendEMailToSelf/";
            //     }
            //     else if (window.location.hostname == "devdeskweb.net") {
            //         UrlFullPath = "http://api.devdeskweb.net/api/EMail/SendEMailToSelf/";
            //     }
            // }
            // else if (window.location.protocol == "https:") { 
            //     if (window.location.hostname == "localhost") {
            //         UrlFullPath = "https://localhost:44388/api/EMail/SendEMailToSelf/";
            //     }
            //     else if (window.location.hostname == "devdeskweb.net") {
            //         UrlFullPath = "https://api.devdeskweb.net/api/EMail/SendEMailToSelf/";
            //     }
            // }
                 
            //UrlFullPath = "http://localhost:54484/api/EMail/PostSendEMail/";
            UrlFullPath = "https://api.devdeskweb.net/api/EMail/PostSendEMail/";

            var myData = {};
            myData.cMessage = MessageCustomer;
            myData.cSubject = SubjectCustomer;
            myData.CustomerMail = MailIDCustomer;
            myData.CustomerPhone = MobileNumberCustomer;
            myData.CustomerName = NameCustomer;
                    
            let options = {
                url: UrlFullPath,
                method: "POST",
                data: myData,
                dataType: "application/json",
                headers: { 'Conent-Type': 'application/json' }
            }
            
            axios(options).then(function (resp) {
                console.log(resp);
                if (resp.data == "success") {
                    alert("EMail sent successfully !");
                }
                else if (resp.data == "error") {
                    alert("error in server Sending EMail !");
                }
                else if (resp.data == "exception") {
                    alert("exception in server Sending EMail !");
                }
            }).catch(function (error) {
                console.log(error);
                alert("Exception in Axios Catch (client side) sending the Mail !");
            });
        } catch (e) {
            console.log(e);
            alert("Exception in try catch (client side) sending the Mail !");
        }
    }
    const GetSendMail = async() => { 
        try {            

            let MessageCustomer = document.getElementById("cMessage").value;
            let SubjectCustomer = document.getElementById("cSubject").value;
            let MailIDCustomer = document.getElementById("cMail").value;
            let MobileNumberCustomer = document.getElementById("cMobile").value;
            let NameCustomer = document.getElementById("cName").value;
                  
            let UrlFullPath = ""; 
            
            // if (window.location.protocol == "http:") {
            //     if (window.location.hostname == "localhost") {
            //         UrlFullPath = "http://localhost:54484/api/EMail/SendEMailToSelf/";
            //     }
            //     else if (window.location.hostname == "devdeskweb.net") {
            //         UrlFullPath = "http://api.devdeskweb.net/api/EMail/SendEMailToSelf/";
            //     }
            // }
            // else if (window.location.protocol == "https:") { 
            //     if (window.location.hostname == "localhost") {
            //         UrlFullPath = "https://localhost:44388/api/EMail/SendEMailToSelf/";
            //     }
            //     else if (window.location.hostname == "devdeskweb.net") {
            //         UrlFullPath = "https://api.devdeskweb.net/api/EMail/SendEMailToSelf/";
            //     }
            // }
                 
            //UrlFullPath = "http://localhost:54484/api/EMail/GetSendEMail/";//https://localhost:44388/
            UrlFullPath = "http://api.devdeskweb.net/api/EMail/GetSendEMail/";
                    
            let params = `cMessage=${MessageCustomer}&cSubject=${SubjectCustomer}&CustomerMail=${MailIDCustomer}&CustomerPhone=${MobileNumberCustomer}&CustomerName=${NameCustomer}`;
            UrlFullPath = UrlFullPath + "?" + params;
                    
            let resp = await axios.get(UrlFullPath);
            console.log(resp);
            if (resp.data == "success") {
                alert("Mail sent successfully !");
            }
            else { 
                alert("Mail sent failure !");
            }           
        } catch (e) {
            console.log(e);
            alert("Exception in sending the Mail !");
        }
    }
    
 
    const resetFormHandler = (evt) => {
        document.getElementById("cMessage").value = "";
        document.getElementById("cSubject").value = "";
        document.getElementById("cMail").value = "";
        document.getElementById("cMobile").value = "";
        document.getElementById("cName").value = "";
    }
    return (
        <>
            <div className="contact">
                <div className="welcome2">Contact Us</div>
                    <div className="grid2">                        
                        <Card className="contact-details-new">
                            <Card.Header>Contact Us</Card.Header>
                            <Card.Body>
                                <Card.Text as={"div"}>
                                    <div className="contact-details">                                                                          
                                        <p>
                                            <FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;+91&nbsp;9989952219
                                        </p>
                                        <p>
                                            <FontAwesomeIcon icon={faSkype} />&nbsp;&nbsp;mohankrishna.manthapuram
                                        </p>
                                        <p>
                                             <FontAwesomeIcon icon={faMailBulk} />&nbsp;&nbsp;info@devdeskweb.net
                                        </p>
                                        <p>
                                          <FontAwesomeIcon icon={faAddressCard} />  &nbsp;&nbsp;Hyderabad City, Telangana State, India.
                                        </p>
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>

          
                        <Card className="contact-details-new">
                            <Card.Header>Send Email</Card.Header>                            
                            <Card.Body>                            
                                   
                                <form id="ContactForm" name="ContactForm" onSubmit={(evt) => { sendMailHandler(evt) }}>                                        
                                        <div className="contact-details-new-in">
                                            <input type="text" id="cName" name="cName" className="form-control form-control-md" placeholder="Name"
                                                required autoComplete="on" minLength="3" maxLength="50" />
                                            <input type="email" id="cMail" name="cMail" className="form-control form-control-md mt-3" placeholder="Email" required autoComplete="on" />
                                            <input type="tel" id="cMobile" name="cMobile" maxLength="15" className="form-control form-control-md mt-3"
                                                placeholder="91-9989952219" pattern="[0-9]{2}-[0-9]{10}" title="Mobile Number" required autoComplete="on" />
                                            <input type="text" id="cSubject" name="cSubject" className="form-control form-control-md mt-3" placeholder="Subject"
                                                required autoComplete="on" minLength="3" maxLength="50" />
                                            <div className="mb-3 mt-3">
                                                <textarea className="form-control" id="cMessage" rows="4" name="cMessage" placeholder="Project Details"
                                                    required minLength="10" maxLength="1000" autoComplete="on"></textarea>
                                            </div>
                                            <div>
                                                <button type="submit" className="btn btn-success mt-3">Send Email</button>
                                                <button type="button" style={{ float: "right" }} className="btn btn-primary mt-3" onClick={(evt) => { resetFormHandler(evt) }}>Reset From</button>
                                            </div>
                                        </div>
                                    </form>                             
                            </Card.Body>
                        </Card>                                       
                    </div>
          
            </div>
        </>
    );
}