import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default function Home() {
    return (
        <>
            
            <div className='home'>
                
              
                <div className='home-hero-image'>
                    <Row> 
                        <Col>
                            <img src="./assets/img/home3.jpg" className='home-image'></img>
                        </Col>
                    </Row>
                </div>
                                                    
                <div className='home-hero-text'>
                    <div className='home-container'>
                        
                        <div className='home-description description' style={{ minWidth: '100%' }}>
                           
                            <div>
                                <h2 className="welcome">Welcome</h2>
                            </div>
                            
                            <Row>
                                <Col>
                                    <p>
                                        We are indipendent software developers. We are providing our services for development, deployment and maintenance
                                        of applications for web, desktop, android and ESRI Arc GIS platforms.<br />
                                        We are expereinced in developing front end, back end, server side applications, web API’s, desktop applications, android apps,
                                        Esri Arc GIS Desktop Customization and ArcGIS Web Applications. We are expereinced in developing responsive, user friendly, interactive, data driven web applications using latest technologies & methodologies.
                                        We are expereinced in developing custom tools and applications for Autodesk Auto CAD and Esri ArcGIS platforms.
                                    </p>
                                </Col>
                            </Row>                                                                                                                                                                  
                            <Row>
                                <Col>
                                    <h4 className="expertise-home">Our Expertise</h4>
                                    <div>
                                        <p>
                                            Microsoft Frameworks & Technologies like .NET, .NET Core, ASP.NET, ASP.NET Core, ASP.NET Web API etc.                                            
                                            Front End Technologies/Frameworks like React, Angular, JavaScript, Boostrap, JQuery etc.
                                            Esri Arc GIS Desktop, and Esri ArcGIS Web Application Development.
                                            Open Source GIS Web Application Development.
                                            Custom tools and applications for Autodesk Auto CAD.
                                            Android Native App Development.
                                        </p>                                                                                                                          
                                    </div>
                                </Col>
                            </Row>
                            {/* <p style={{ color: 'steelblue', marginTop: "5px" }}>
                                We are ready to accept projects from any Company or Contractor or Subcontractor or any Individual.
                            </p>                                                  */}
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
            </div>
        </>
    );
}