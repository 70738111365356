import React from 'react'
import { Table } from 'react-bootstrap'

const Products = () => {
    return (
        <>
            <div className='my-products'>
                <p className="products-header">Products</p>
                <br />
                <Table bordered="true">
                    <thead>
                        <tr>
                            <th className='w-25 ps-2'>Product Name</th>
                            <th className='ps-3'>Product Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className='pt-3 pe-2 pb-1'>                               
                                    <h5 className=' text-decoration-underline'>1. User Identity System</h5>
                                    
                                    <p className=''>
                                        Single Factor Authentication implemented.                                  
                                </p>
                                <p>
                                    <a target='_blank' href='https://identity.devdeskweb.net' rel='noreferrer'>Click here </a> To visit the application.
                                    </p> 
                                 </div>    
                            </td>
                            <td>                                                            
                                <ul className='pt-3 pe-2 pb-1'>
                                    <li className='pb-2'>
                                        'User Identity System' main objective is to provide authentication(login) and authorization(access secure resource) functionality to the web applications.
                                        It is developed as 2 parts, i.e. 1. Frontend and 2. Backend.
                                    </li>
                                    <li className='pb-2'>
                                        <b>Frontend</b> is a client side web application developed in React, it contains UI & options needed for end user.
                                    </li>
                                    <li className='pb-2'>
                                        <b>Backend</b> is Web API developed in ASP.NET Core, it contains server side web methods to provide the required Security and User management system.
                                    </li>
                                    <li>
                                        Frontend & Backend of the User Identity System is developed based on ASP.NET Core Identity concept.
                                    </li>
                                    <li>
                                        Please go through the Home page for full details.
                                    </li>
                                </ul>                                                                                                    
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className='pt-3 pe-2 pb-1'>                                       
                                <h5  className=' text-decoration-underline'>2. Two Factor Authentication</h5>
                                    <p className=''>
                                        Two Factor Authentication implemented.                                       
                                </p>
                                <p>
                                    <a target='_blank' href='https://2fa.devdeskweb.net/' rel='noreferrer'>Click here </a> To visit the application.
                                    </p> 
                                 </div>    
                            </td>
                            <td>                                                            
                                <ul className='pt-3 pe-2 pb-1'>
                                    <li className='pb-2'>
                                       It is an upgraded version of <a href='https://identity.devdeskweb.net' target='_blank' rel='noreferrer'><b>User Identity System</b></a>, listed above in Product No. 1.
                                    </li>
                                    <li className='pb-2'>
                                        <b>Two factor Authentication</b> is implementing to check the logged in User Identity in this application to provide additional security.
                                    </li>
                                    <li className='pb-2'>
                                        Remaining all functionalities will work same as in the <a href='https://identity.devdeskweb.net' target='_blank' rel='noreferrer'><b>User Identity System</b></a> listed above in Product No. 1.
                                    </li> 
                                     <li>
                                        Please go through the Home page for full details.
                                    </li>
                                </ul>                                                                                                    
                            </td>
                        </tr>
                    </tbody>
                </Table>
                           
                
                  
                
                
                {/* 
                    <div className='grid-tem'>
                        <div className="">
                            <h4>2. Product 2</h4>
                            <p>
                                We developed User Identity application. It works in Frontend & Backend style approach. Frontend is developed in React and
                                Backend wEB apI is developed in ASP.NET Core. To navigate to the Identity application, please
                                <a target='_blank' href='https://identity.devdeskweb.net'> click here</a>
                            </p>
                        </div>
                    </div>
                    <div className='grid-tem'>
                        <div className="">
                            <h4>Product 2 Description</h4>
                            <p>
                                We developed User Identity application. It works in Frontend & Backend style approach. Frontend is developed in React and
                                Backend wEB apI is developed in ASP.NET Core. To navigate to the Identity application, please
                                <a target='_blank' href='https://identity.devdeskweb.net'> click here</a>
                            </p>
                        </div>
                    </div> */}

                    
                    
            </div>
            <br></br>
            <br></br>
        </>
    
    )
}

export default Products