import React from 'react'
import { Outlet } from 'react-router-dom'
import NavbarComponent from '../Components/NavbarComponent'
import Footer from '../Components/Footer'

function Layout() {
  return (
    <>
      
        <div className="">
          <NavbarComponent />
          <Outlet />
          <Footer />
        </div>                       
    </>
  );
}

export default Layout