export default function Footer() {
    const copyright = () => {
        return new Date().getFullYear()
    }
    return (
        <>
            {/* <div className="footer" >
                <p >
                   <span style={{verticalAlign:'text-top'}}>&copy; </span> {new Date().getFullYear()} www.devdeskweb.net
                </p> 
            </div> */}
            <div className='fixed-bottom pt-2 footer'>                             
                    <div>&copy; {new Date().getFullYear()} www.devdeskweb.net</div>                
            </div>
        </>
    );
}

